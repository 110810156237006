<template>
  <div class="pricing-hero-banner brand-sky-blue-bg">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="hero-content mx-auto">
        <!-- TITLE TEXT  -->
        <div
          class="title-text text-center brand-dark-blue font-weight-700"
          data-aos="fade-up"
          data-aos-offset="150"
          data-aos-delay="60"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Prepare for multiple examinations for the price of one!
        </div>

        <!-- META TEXT  -->
        <div
          class="meta-text text-center brand-dark-blue"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="120"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Subscribe for as low as ₦2,000 to gain full access to comprehensive
          past questions and answers, a quick revision guide, an exam simulator,
          a question engine, and a cloud-based analytics platform for WAEC,
          JAMB, and POST UTME
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricingHeroBanner",
};
</script>

<style lang="scss" scoped>
.pricing-hero-banner {
  padding: toRem(130) 0 toRem(150);

  @include breakpoint-down(md) {
    padding: toRem(100) 0 toRem(150);
  }

  @include breakpoint-down(md) {
    padding: toRem(110) 0;
  }

  @include breakpoint-down(sm) {
    padding: toRem(100) 0;
  }

  .hero-content {
    @include flex-column-center;

    .title-text {
      @include font-height(74, 84);
      margin-bottom: toRem(28);
      width: 80%;

      @include breakpoint-down(xl) {
        @include font-height(68, 80);
      }

      @include breakpoint-down(lg) {
        @include font-height(62, 76);
        width: 74%;
      }

      @include breakpoint-down(md) {
        @include font-height(54, 68);
        width: 80%;
      }

      @include breakpoint-down(sm) {
        @include font-height(42, 58);
        width: 86%;
      }

      @include breakpoint-down(xs) {
        @include font-height(38, 52);
      }
    }

    .meta-text {
      @include font-height(18, 28);
      margin-bottom: toRem(48);
      width: 74%;

      @include breakpoint-down(xl) {
        width: 80%;
      }

      @include breakpoint-down(md) {
        width: 88%;
      }

      @include breakpoint-down(sm) {
        @include font-height(16, 28);
        width: 90%;
      }

      @include breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}
</style>
